/** @format */
'use strict'
jQuery(function ($) {
// Variable set up

  const $body = $('body')

  // It's header time

  let savedScrollPos
  let headShown = false

  $('#head-toggle').click(function (e) {
    e.preventDefault()
    if (headShown) {
      removeHead()
    } else {
      showHead()
    }
    headShown = !headShown
  })

  function showHead () {
    savedScrollPos = window.pageYOffset
    $body.toggleClass('state--nav-open state--nav-closed')
    $('.c-open-header').toggleClass('u-hidden-visually')
  }

  function removeHead () {
    $body.toggleClass('state--nav-open state--nav-closed')
    $('.c-open-header').toggleClass('u-hidden-visually')
    window.scrollTo(0, savedScrollPos)
  }

  // Esc closes header menu

  const KEYCODE_ESC = 27
  $(document).keyup(function (e) {
    if (e.keyCode === KEYCODE_ESC) {
      if (headShown) {
        removeHead()
        headShown = !headShown
      }
    }
  });

  // Compact header on scroll

  (function () {
    let scroll = 0

    $(window).scroll(function () {
      scroll = $(window).scrollTop()

      if (scroll >= 60) {
        $body.addClass('state--header-compact')
      } else {
        $body.removeClass('state--header-compact')
      }
    })
  })()
})
